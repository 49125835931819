import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import config from '../utils/config'

/**
 * a custom hook for retrieving files for the logged in user
 *
 * @prop {Object} defaultValue - a default value for the hook to take prior to the user files
 * loading. we set it to an object containing a dummy ID and an empty entity name so that when
 * rendering to an mui-data-grid, a single empty row while the user files load
 */
export const useUploadFile = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)

  // asynchronously fetch our access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: 'frappe-snowland-api',
      })
      setToken(token)
    }
    getAccessToken()
  }, [])

  const uploadFile = async (file, originalKey) => {
    if (!token) return
    const key = originalKey.replaceAll('addresses', 'serviceability')
    const response = await fetch(config.services.filePresignedURL, {
      headers: {
        authorization: token,
      },
      body: JSON.stringify({
        key,
        upload: true,
      }),
      method: 'POST',
    })

    const link = await response.json()
    const reader = new FileReader()

    reader.onload = (e) => {
      return fetch(link, {
        method: 'PUT',
        body: e.target.result,
      })
    }

    reader.readAsText(file)
  }

  return uploadFile
}

export default useUploadFile
