import Header from '../Header'
import { Box } from '@mui/system'
import 'regenerator-runtime/runtime'

const withLayout = (Component) => {
  const ComponentWithLayout = () => (
    <Box sx={{ marginTop: '77px' }}>
      <Header />
      <Component />
    </Box>
  )

  return ComponentWithLayout
}

export default withLayout
