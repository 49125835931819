import { withAuthenticationRequired } from '@auth0/auth0-react'
import { UserProvider } from '../../state/user/context'
import Loading from '../Loading'
import withLayout from './withLayout'

const withAuthLayout = (Component) => {
  const WrappedComponent = withLayout(Component)

  const withProviders = () => (
    <UserProvider>
      <WrappedComponent />
    </UserProvider>
  )

  return withAuthenticationRequired(withProviders, {
    onRedirecting: () => <Loading />,
  })
}

export default withAuthLayout
