// React imports
import { useState } from 'react'
import { Link } from 'react-router-dom'
// MUI imports
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Fade,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
// local imports
import { useUser } from '../../state/user/context'
import isAdmin from '../../utils/adminHelpers'

/**
 * This component is a dropdown displayed on the right side of the navbar which is used for
 * navigation throughout the app, as well as logging out.
 */
const AccountDropdown = () => {
  const classes = useStyles()
  const { name, picture, logout, role } = useUser()
  // this is the dom element to which we will anchor the menu
  const [anchorElement, setAnchorEl] = useState(null)

  /**
   * a little helper function used to close the menu
   */
  const handleClose = () => setAnchorEl(null)

  /**
   * this component will be used for each item in our menu
   *
   * @prop {String} linkTo - the path or address which the item redirects to
   * @prop {String} text - the text to be displayed on the menu item
   * @prop {Boolean} newTab - whether or not to open in a new tab
   * @prop {Function} onClick - an optional function to be executed on click
   */
  const ListItem = ({ linkTo, text, newTab, onClick = () => {} }) => {
    return newTab ? (
      <a
        className={classes.menuLink}
        href={linkTo}
        target='_blank'
        rel='noopener noreferrer'>
        <MenuItem onClick={handleClose}>
          <ListItemText
            className={classes.listItem}
            primary={text}
            onClick={onClick}
          />
        </MenuItem>
      </a>
    ) : linkTo ? (
      <Link className={classes.menuLink} to={linkTo}>
        <MenuItem onClick={handleClose}>
          <ListItemText
            className={classes.listItem}
            primary={text}
            onClick={onClick}
          />
        </MenuItem>
      </Link>
    ) : (
      <MenuItem onClick={handleClose}>
        <ListItemText
          className={classes.listItem}
          primary={text}
          onClick={onClick}
        />
      </MenuItem>
    )
  }

  return (
    <>
      {name && (
        <>
          <Box onClick={(event) => setAnchorEl(event.currentTarget)}>
            <Grid container direction='row'>
              <Grid item>
                <Avatar alt={name} src={picture} />
              </Grid>
              <Grid item>
                <IconButton aria-label='close'>
                  {anchorElement ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Menu
            classes={{ paper: classes.menuPaper }}
            elevation={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id='user-menu'
            anchorEl={anchorElement}
            keepMounted
            TransitionComponent={Fade}
            open={Boolean(anchorElement)}
            onClose={handleClose}>
            <ListItem linkTo='/profile' text='Profile' />
            <ListItem linkTo='/dashboard' text='File Dashboard' />
            <ListItem
              linkTo='https://www.educationsuperhighway.org/terms-of-service/'
              text='Terms and Conditions'
              newTab
            />
            <ListItem
              linkTo='https://educationsuperhighway.zendesk.com'
              text='Request Help'
              newTab
            />
            {name && isAdmin(role) && (
              <ListItem
                linkTo='/admin'
                onClick={handleClose}
                text='Admin Dashboard'
              />
            )}
            <ListItem text='Logout' onClick={logout} />
          </Menu>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    border: '1px solid #d3d4d5',
    color: 'black',
    marginTop: '12px',
  },
  menuLink: {
    textDecoration: 'none',
  },
  listItem: {
    color: theme.palette.primary.dark,
  },
}))

export default AccountDropdown
