import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Link, Paper, Typography, Checkbox } from '@mui/material'
import { useUser } from '../../state/user/context'
import { withAuthLayout } from '../../components/withLayout'
import { Box } from '@mui/system'
import useUpdateUser from '../../hooks/useUpdateUser'

/**
 * A component to display profile information
 *
 * @prop {Object} children - the child elements to this component
 * @prop {String} title - the label for the field
 */
const Field = ({ children, title }) => (
  <Box sx={{ marginTop: 3 }}>
    <div>
      <strong>{title}:</strong>
    </div>
    {children}
  </Box>
)

/**
 * A grid of frns to display on this project page
 *
 * @prop {Array} frns - an array of frn objects to be displayed in the grid
 */
const FrnGrid = ({ frns }) => (
  <>
    {frns.map(({ frn, entities }) => {
      // remove duplicate DBA values
      const dbas = Array.from(new Set(entities.map((entity) => entity.dba)))
      return (
        <Box key={frn} display='grid' gridTemplateColumns='repeat(60, 1fr)'>
          <Box display='grid' gridColumn='span 12' justifyContent='end'>
            {frn}
          </Box>
          {/* a little bit of space */}
          <Box gridColumn='span 2'></Box>
          {/* in the mockups, the opening bracket was in a separate flow from the list items */}
          <Box
            gridColumn='minmax(span 1, 8px)'
            display='grid'
            justifyContent='end'>
            (
          </Box>
          <Box gridColumn='span 45'>
            {dbas.map((dba, i) => (
              <Box key={dba} display='grid' justifyContent='start'>
                {dba}
                {/* only close the bracket above if it's the last item */}
                {i === dbas.length - 1 ? ')' : ''}
              </Box>
            ))}
          </Box>
        </Box>
      )
    })}
  </>
)

/**
 * the Profile page!
 */
const Profile = () => {
  const classes = useStyles()
  const user = useUser()
  const updateUser = useUpdateUser()
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  // if user.notify changes, checked should change to reflect that value
  useEffect(() => {
    if (typeof user?.notify === 'boolean') {
      setLoading(false)
      setChecked(user?.notify)
    }
  }, [user])

  const handleChange = async (event) => {
    setLoading(true)

    const body = {
      email: user?.email,
      notify: event.target.checked,
      role: user?.role,
      validationStatus: user?.validation_status,
      organizationId: user?.organization_id,
      name: user?.name,
    }
    const result = await updateUser(body)

    if (result.ok) {
      setLoading(false)
      setChecked(!event.target.checked)
    } else {
      console.error('Not able to update user with notification selection.')
    }
  }
  /**
   * A function that determines 2 different prompts to potentially show the user if they:
   * A) have not verified their email, OR
   * B) have not been validated
   */
  const displayUserNextActionPrompt = () => {
    if (!user?.email_verified) {
      return (
        <>
          <Paper
            variant='outlined'
            className={classes.displayUserNextActionPromptPaper}>
            <Typography variant='body1'>
              <strong>Verify your email address</strong>
            </Typography>
            <Typography variant='body1'>
              Please check your email inbox for a verification link. Click the
              verification link to activate your account.
            </Typography>
          </Paper>
        </>
      )
    }
    if (user?.email_verified && !user?.validation_status) {
      return (
        <>
          <Paper
            variant='outlined'
            className={classes.displayUserNextActionPromptPaper}>
            <Typography variant='body1'>
              <strong>Account Status: Pending</strong>
            </Typography>
            <Typography variant='body1'>
              Your account has not yet been activated by an administrator.
              Before activating we verify that your email address is associated
              with an internet service provider. If you signed up more than 24
              hours ago and you are still seeing this message, please contact us
              at tech.team@educationsuperhighway.org for assistance.
            </Typography>
          </Paper>
        </>
      )
    }
    return null
  }

  return (
    <Paper elevation={3} className={classes.profilePanel}>
      <Grid item xs={12}>
        {/* Make sure validation_status exists before calling the function */}
        {user.hasOwnProperty('validation_status')
          ? displayUserNextActionPrompt()
          : null}
      </Grid>
      <Typography className={classes.profileTitle}>PROFILE</Typography>
      <div>
        <img
          src={user?.picture}
          alt='profilePicture'
          className={classes.picture}
        />
      </div>
      <Grid container spacing={3}>
        <Grid item md={5} sm={12}>
          <Field title='Name'>{user?.name}</Field>
          <Field title='Email'>{user?.email}</Field>
          <Field title='Organization'>{user?.organization_name || '...'}</Field>
          <Link
            href='/dashboard'
            underline='none'
            style={{ color: '#019297', fontWeight: 'bold' }}>
            File Dashboard
          </Link>
          <Field title='Email Verified'>
            {String(user?.email_verified || '...')}
          </Field>
          <Field title='Account Status'>
            {user.hasOwnProperty('validation_status')
              ? user?.validation_status === true
                ? 'Validated'
                : 'Pending'
              : '...'}
          </Field>
          <Field title='Role'>{user?.role}</Field>
        </Grid>
        <Grid item md={7} sm={12}>
          <Field title='Associated FRNs'>
            {Array.isArray(user?.frns) && <FrnGrid frns={user.frns} />}
          </Field>
          <Field title='Notifications'>
            <Checkbox
              indeterminate={loading}
              checked={checked}
              color={'success'}
              onChange={handleChange}
            />
            Send me an email when new files are added to the Files Dashboard
          </Field>
        </Grid>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  picture: {
    borderRadius: '50%',
    height: '150px',
    margin: '0 auto',
    imageRendering: 'smooth',
  },
  profilePanel: {
    margin: `${theme.spacing(12)} auto auto auto`,
    width: '80vw',
    minWidth: '800px',
    padding: theme.spacing(4),
    borderRadius: '25px',
  },
  profileTitle: {
    marginBottom: theme.spacing(4),
    color: 'grey',
    fontSize: '32px',
  },
  displayUserNextActionPromptPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
    borderRadius: '15px',
    backgroundColor: '#efe6ec', // 90% less of shade theme.palette.secondary.main
    border: '1px solid #bf9cb2', // 60% less of shade theme.palette.secondary.main
    overflowWrap: 'break-word',
  },
}))

export default withAuthLayout(Profile)
