import { useState } from 'react'
import { Button, Modal, Paper, Typography, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

export const UserGuideModal = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button
        onClick={handleOpen}
        className={classes.userGuideButton}
        color='success'
        size='large'
        variant='contained'>
        User Guide
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Paper elevation={3} className={classes.userGuideModal}>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          <Typography
            id='modal-modal-title'
            variant='h5'
            component='h2'
            sx={{ mb: 1 }}>
            User Guide
          </Typography>
          <Typography id='modal-modal-title'>
            After you download an address file, add a value to the
            serviceability column in the file.
          </Typography>
          <Typography id='modal-modal-description'>
            Possible serviceability values are:
          </Typography>
          <ul>
            <Typography
              id='modal-modal-description'
              sx={{ mt: 1, ml: 3, mb: 2 }}>
              <li>
                <strong>0 - No service available</strong> at this address
              </li>
              <li>
                <strong>1 - Service available</strong> at this address
              </li>
              <li>
                <strong>2 - Active service</strong> at this address
              </li>
            </Typography>
          </ul>
          <Typography id='modal-modal-title'>
            After adding serviceability values for each address, return to this
            site to upload the file.
          </Typography>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  userGuideModal: {
    padding: theme.spacing(6),
    borderRadius: '25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
  userGuideButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    left: '95%',
    top: '2%',
  },
}))

export default UserGuideModal
