import { Box } from '@mui/system'
import { memo } from 'react'

/**
 * A simple memoized component for a horizontal unordered list
 *
 * @prop {Array} children
 * @prop {String} className
 * @prop {Number} columns - the number of columns to be used
 * @returns
 */
const HorizontalUL = ({ children, columns = 3, className }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridAutoRows: '30px',
        gap: '5px',
      }}
      className={className}>
      {Array.isArray(children) &&
        children.map((child, i) => <ul key={i}>{child}</ul>)}
    </Box>
  )
}

export default memo(HorizontalUL)
