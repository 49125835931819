import { createContext, useContext, useEffect, useReducer } from 'react'
import userReducer from './reducer'
import useUserDetails from '../../hooks/useUserDetails'
import { addToUserAction } from './actions'
import { useAuth0 } from '@auth0/auth0-react'

// the User Context will store all of our information about the logged-in user
const UserContext = createContext()

// the User Provider will give us access to the data stored in our context
const UserProvider = ({ children }) => {
  //  for our initial state, we'll grab the user details from Auth0, as well as the logout function
  const { user, logout } = useAuth0()
  const initialState = {
    ...user,
    logout,
  }
  // useReducer allows us to use a Redux-style reducer with our context
  const [state, dispatch] = useReducer(userReducer, initialState)
  // use our custom hook to fetch the user details from our db
  const userDetails = useUserDetails()
  // and listen for changes in that hook, adding whatever details come through to our user context
  useEffect(() => {
    if (userDetails) dispatch(addToUserAction(userDetails))
  }, [userDetails])

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>
}

/**
 * rather than importing React.useContext and UserContext wherever we want to get user details, we
 * export a hook which does that for us, so we need only one import elsewhere.
 */
const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser }
