import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'
import useAllOrgs from '../../../hooks/useAllOrgs'
import { Box } from '@mui/system'
import OrgModal from '../../Modal/OrgModal'
import { SearchToolbar, gridFilter } from '../SearchToolbar'
import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

/**
 * A component for the table of organizations in the admin interface
 */
const OrgsTable = () => {
  const classes = useStyles()
  const { orgs, refetchOrgs, loading } = useAllOrgs()
  const [modalOrg, onlySetModalOrg] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])

  // when we close the modal, let's refetch the organizations to see any updates
  const setModalOrg = (org) => {
    onlySetModalOrg(org)
    refetchOrgs()
  }

  // the MUI Data Table component requires that each row have an ID prop, so we'll add it
  useEffect(() => {
    if (!orgs) return
    setRows(
      (Array.isArray(orgs) &&
        orgs.map((org) => ({ ...org, id: org.organization_id }))) ||
        []
    )
  }, [orgs])

  /* 
   since we filter rows, we keep both rows and filtered rows in state. when rows changes (should 
   only happen once), we set it into state.
   */
  useEffect(() => {
    setFilteredRows(rows)
  }, [rows])

  // the filtering function for the search bar
  const requestSearch = (searchText) => {
    setSearchText(searchText)
    const filteredRows = rows.filter(gridFilter(searchText))
    setFilteredRows(filteredRows)
  }

  // to add an org, all we need to do is "update" an org which doesn't have an id
  const handleAddOrg = () => {
    setModalOrg({
      organization_name: '',
      service_provider_ids: [],
    })
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'header',
      flex: 1,
    },
    {
      field: 'organization_name',
      headerName: 'Name',
      headerClassName: 'header',
      flex: 10,
    },
    {
      field: 'service_provider_ids',
      headerName: 'Service Provider IDs',
      headerClassName: 'header ' + classes.hideRightSeparator,
      flex: 20,
    },
  ]

  return (
    <Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mt: -0.5 }}>
        <SearchToolbar
          sx={{ justifySelf: 'start' }}
          value={searchText}
          onChange={(event) => requestSearch(event.target.value)}
          clearSearch={() => requestSearch('')}
        />
        <IconButton
          sx={{ justifySelf: 'end', margin: 1.5, marginBottom: '-1px' }}
          color='success'
          size='small'
          onClick={handleAddOrg}>
          <AddIcon />
        </IconButton>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        loading={loading}
        pageSize={10}
        autoHeight={true}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        onCellClick={({ row }) => {
          setModalOrg(row)
        }}
      />
      <OrgModal
        org={modalOrg}
        open={Boolean(modalOrg)}
        onClose={() => {
          setModalOrg(null)
        }}
      />
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  hideRightSeparator: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}))

export default OrgsTable
