// React imports
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
// MUI imports
import { AppBar, Toolbar, Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
// local imports
import AccountDropdown from '../AccountDropdown'
import eshLogo from '/src/assets/ESH_Logo_Horizontal.png'

/**
 * This component is the navbar for the site. It is included in the withLayout component, and so
 * you shouldn't need to use it directly.
 */
const Header = () => {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <AppBar color='inherit'>
      <Toolbar>
        <Grid container alignItems='center' spacing={2} minWidth='550px'>
          <Grid item>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.educationsuperhighway.org/'>
              <img
                className={classes.logo}
                style={{ height: 54 }}
                alt='esh-logo-children-holding-hands'
                src={eshLogo}
              />
            </a>
          </Grid>
          <Grid item>
            <div className={classes.divider} />
          </Grid>
          <Grid item>
            <Typography
              variant='h6'
              className={classes.topLeft}
              onClick={() => {
                navigate('/')
              }}>
              <span className={classes.header}>Service Provider Access</span>
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid item minWidth='90px'>
          <AccountDropdown />
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles(() => ({
  topLeft: {
    cursor: 'pointer',
  },
  logo: {
    // HACK: this makes the logos line up
    marginTop: 3,
  },
  divider: {
    borderLeft: '2px solid lightgray',
    // HACK: this makes the dividers line up
    marginLeft: 1,
    height: 42,
  },

  header: {
    // NO HACK: I refuse to make the text line up – it's not centered in Rainbow Road
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 10,
    textTransform: 'uppercase',
  },
}))

export default memo(Header)
