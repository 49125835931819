import Login from '../../components/Login'
import { Box } from '@mui/system'
import { withLayout } from '../../components/withLayout'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

const Home = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) navigate('/profile')
  }, [isAuthenticated])

  return (
    <>
      {!isLoading && (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 77px)',
            display: 'grid',
            alignContent: 'center',
            justifyContent: 'center',
          }}>
          <Login />
        </Box>
      )}
    </>
  )
}

export default withLayout(Home)
