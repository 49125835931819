import React, { useEffect } from 'react'
import { Typography, Button, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import signupLogo from '../../assets/ESH-logomark.png'
import { Box } from '@mui/system'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 'bold',
    width: 180,
  },
  root: {
    padding: theme.spacing(2),
    width: 300,
  },
}))

const SignIn = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/profile')
    }
  }, [isAuthenticated])

  const classes = useStyles()

  return (
    <Paper elevation={4} className={classes.root}>
      <Typography align='center'>
        <img style={{ width: 90 }} alt='logo' src={signupLogo} />
      </Typography>
      <Typography variant='h5' align='center' gutterBottom={true}>
        Create Account
      </Typography>

      <Box sx={{ width: '100%', display: 'grid', justifyContent: 'center' }}>
        <Button
          color='success'
          className={classes.button}
          onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
          size='large'
          variant='contained'>
          Sign Up
        </Button>
        <Typography variant='body1' align='center'>
          or
        </Typography>
        <Button
          color='success'
          className={classes.button}
          onClick={() => loginWithRedirect({})}
          size='large'
          variant='contained'>
          Log In
        </Button>
      </Box>
    </Paper>
  )
}

export default SignIn
