import UploadIcon from '@mui/icons-material/CloudUploadOutlined'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useUploadFile } from '../../hooks/useUploadFile'
import { useUser } from '../../state/user/context'
import { matchFile } from '../../utils/file'
import { SuccessAnimation } from '../SuccessAnimation'

const UploadModal = ({ availableAddresses, open, onClose }) => {
  const classes = useStyles()
  const uploadFile = useUploadFile()
  const { frns } = useUser()

  // the file to be uploaded
  const [file, setFile] = useState(null)
  /* 
   indicators for loading and success, with setters for exclusively one or the other. in practice,
   we set both at the same time, which you can see below with the (expected) identifiers:
   setLoading and setSuccess
   */
  const [loading, setOnlyLoading] = useState(false)
  const [success, setOnlySuccess] = useState(false)
  // the district selected to assign to the uploaded file
  const [districtKey, setDistrictKey] = useState('')
  // a guess for what district a file belongs to
  const [match, setMatch] = useState(null)
  // a warning if the file appears to belong to a district other than the one selected
  const [helpText, setHelpText] = useState(null)
  // a control that determines whether we're prompting the user to confirm their decision
  const [confirming, setConfirming] = useState(false)

  /**
   * setter for the loading variable which also sets the success variable, as expected
   */
  const setLoading = (status) => {
    setOnlyLoading(status)
    setOnlySuccess(!status)
  }

  /**
   * setter for the success variable which also sets the loading variable, as expected
   */
  const setSuccess = (status) => {
    setOnlySuccess(status)
    setOnlyLoading(!status)
  }

  /**
   * a builder for descriptive warning messages
   */
  const matchErrorWarning = () => {
    // make a guess as to what district/state the selected file belongs to
    const guess = matchFile(file?.name, availableAddresses, frns)
    // and, if one exists, set our help text
    return guess?.entityName
      ? `The selected file looks like it belongs to ${guess.entityName}`
      : null
  }

  /**
   * a getter for the entity name of a given key
   */
  const getName = (key) => {
    return availableAddresses.find((address) => address.Key === key)?.entityName
  }

  // make a guess at what district/state the file belongs to whenever the file changes
  useEffect(() => {
    if (file?.name) {
      const match = matchFile(file?.name, availableAddresses, frns)
      if (match?.Key) {
        setDistrictKey(match.Key)
        setMatch(match)
      }
    } else {
      setMatch(null)
    }
  }, [file])

  // if the above match is made, and it varies from the selection, set warning text
  useEffect(() => {
    if (match?.Key) {
      if (districtKey && match.Key !== districtKey) {
        setHelpText(matchErrorWarning(districtKey))
      } else {
        setHelpText(null)
      }
    }
  }, [match, districtKey, file])

  // handler for the district dropdown
  const handleDistrictChange = (event) => {
    setDistrictKey(event.target.value)
  }

  // on close of the modal, we want to reset the state to its defaults
  const resetStateAndClose = () => {
    setFile(null)
    setOnlyLoading(false)
    setOnlySuccess(false)
    setDistrictKey('')
    setMatch(null)
    setHelpText(null)
    onClose(true)
  }

  /**
   * handler for the confirm dialog
   */
  const handleConfirm = (result) => {
    setConfirming(false)
    if (result) {
      handleUploadFile()
    }
  }

  /**
   * handler for the upload button
   */
  const handleSubmit = () => {
    // if there's help text, there's a mismatch
    if (helpText) {
      setConfirming(true)
    } else {
      handleUploadFile()
    }
  }

  /**
   * the actual submit action, uploading the file
   */
  const handleUploadFile = () => {
    setLoading(true)
    uploadFile(file, districtKey)
      .then(() => {
        // trigger the success animation, and set for it to go away after 2.3 seconds
        setSuccess(true)
        setTimeout(() => {
          resetStateAndClose()
        }, 2300)
      })
      .catch((error) => {
        // todo make this suck less
        alert('Sorry! Something went wrong.' + '\n' + error?.message)
      })
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={resetStateAndClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Paper elevation={3} className={classes.uploadModal}>
          {/*
            determine what to display between the following options:
              • upload file dialog (default)
              • confirm dialog
              • loading animation
              • success animation

            these option follow, in the order listed.
          */}
          {/* upload file dialog (default) */}
          {!success && !loading && !confirming && (
            <>
              <Typography id='modal-modal-title' variant='h5' component='h2'>
                Upload Processed Addresses
              </Typography>
              <Typography
                variant='h6'
                id='modal-modal-description'
                sx={{ mt: 2 }}>
                Choose a file from your computer
              </Typography>
              <input
                accept='.csv'
                className={classes.input}
                style={{ display: 'none' }}
                id='raised-button-file'
                type='file'
                onChange={(event) => setFile(event.target.files[0])}
              />
              <label htmlFor='raised-button-file'>
                <Button
                  component='span'
                  color='secondary'
                  variant='outlined'
                  size='large'
                  className={classes.uploadButton}>
                  <UploadIcon />
                  <Typography sx={{ ml: 1 }}>Choose file</Typography>
                </Button>
              </label>
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{ mt: 1, ml: 2, display: 'block' }}>
                {file?.name || 'No file selected'}
              </Typography>
              <Typography
                variant='h6'
                id='modal-modal-description'
                sx={{ mt: 2 }}>
                Choose the district or state
              </Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  value={districtKey}
                  onChange={handleDistrictChange}>
                  <MenuItem disabled value=''>
                    <em>None</em>
                  </MenuItem>
                  {availableAddresses.map(({ entityName, entityId, Key }) => (
                    <MenuItem key={Key} value={Key}>
                      {`${entityName} (${entityId})`}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={helpText && classes.helpText}>
                  {helpText ||
                    'This is the district or state to which the addresses in the selected file belong.'}
                </FormHelperText>
              </FormControl>
              <Button
                color='secondary'
                onClick={handleSubmit}
                size='large'
                variant='contained'
                sx={{ mt: 2, display: 'block' }}
                disabled={!file || !districtKey}>
                Submit
              </Button>
            </>
          )}
          {/* confirm dialog */}
          {!success && !loading && confirming && (
            <Box
              sx={{
                display: 'grid',
                alignContent: 'center',
                height: '100%',
              }}>
              <div>
                <Typography id='modal-modal-title' variant='h5' component='h2'>
                  Are You Sure?
                </Typography>
                <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  {helpText + '.'}
                </Typography>
                <Typography id='modal-modal-description' sx={{ mt: 1 }}>
                  {`Are you sure you want to upload it for ${getName(
                    districtKey
                  )} instead?`}
                </Typography>
                <Button
                  sx={{ mt: 3, mr: 2 }}
                  color='secondary'
                  size='large'
                  variant='contained'
                  onClick={() => handleConfirm(true)}
                  autoFocus>
                  Continue
                </Button>
                <Button
                  sx={{ mt: 3 }}
                  color='success'
                  size='large'
                  variant='contained'
                  onClick={() => handleConfirm(false)}>
                  Cancel
                </Button>
              </div>
            </Box>
          )}
          {/* loading animation */}
          {loading && !success && (
            <Box
              sx={{
                display: 'grid',
                alignContent: 'center',
                justifyContent: 'center',
                height: '100%',
              }}>
              <CircularProgress color='secondary' />
            </Box>
          )}
          {/* success animation */}
          {success && !loading && <SuccessAnimation />}
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  helpText: {
    color: 'red',
    '&::before': {
      content: '"** "',
    },
  },
  uploadModal: {
    padding: theme.spacing(4),
    borderRadius: '25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 425,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
  uploadButton: {
    marginTop: theme.spacing(1),
  },
}))

export default UploadModal
