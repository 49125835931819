import { memo } from 'react'
import { LinearProgress, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

/**
 * A simple, reusable component to indicate a loading state
 */
const Loading = ({ sx = {}, circular }) => {
  const containerStyle = {
    width: '100%',
    height: circular ? '50vh' : 'calc(100vh - 77px)',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    ...sx,
  }

  return (
    <Box sx={containerStyle}>
      {circular ? (
        <CircularProgress color='success' />
      ) : (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color='success' />
        </Box>
      )}
    </Box>
  )
}

export default memo(Loading)
