/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'
import useAllUsers from '../../../hooks/useAllUsers'
import { Box } from '@mui/system'
import EditUserModal from '../../Modal/EditUserModal'
import { SearchToolbar, gridFilter } from '../SearchToolbar'

/**
 * A component for the table of users in the admin interface
 */

const UsersTable = () => {
  const classes = useStyles()
  const { users, refetchUsers, loading } = useAllUsers()

  const [searchText, setSearchText] = useState('')
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [modalOpen, onlySetModalOpen] = useState(false)
  const [modalUser, setModalUser] = useState(null)

  // when we close the modal, let's refetch the users to see any updates
  const setModalOpen = (modalOpen) => {
    refetchUsers()
    onlySetModalOpen(modalOpen)
  }

  // the MUI Data Table component requires that each row have an ID prop, so we'll add it
  useEffect(() => {
    if (!users) return
    setRows(
      (Array.isArray(users) && users.map((user, id) => ({ ...user, id }))) || []
    )
  }, [users])

  /* 
   since we filter rows, we keep both rows and filtered rows in state. when rows changes (should 
   only happen once), we set it into state.
   */
  useEffect(() => {
    setFilteredRows(rows)
  }, [rows])

  // the filtering function for the search bar
  const requestSearch = (searchText) => {
    setSearchText(searchText)
    const filteredRows = rows.filter(gridFilter(searchText))
    setFilteredRows(filteredRows)
  }

  const columns = [
    {
      field: 'id',
      headerClassName: 'header',
      headerName: 'ID',
      hide: 'true',
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'header',
      flex: 2,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerClassName: 'header',
      flex: 8,
    },
    {
      field: 'validation_status',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
    },
    {
      field: 'notify',
      headerName: 'Notify',
      headerClassName: 'header',
      hide: 'true',
    },
    {
      field: 'role',
      headerClassName: 'header',
      headerName: 'Role',
      flex: 1,
      hide: 'true',
    },
    {
      field: 'organization_name',
      headerName: 'Organization',
      headerClassName: 'header ' + classes.hideRightSeparator,
      flex: 5,
    },
  ]

  return (
    <Box>
      <SearchToolbar
        value={searchText}
        onChange={(event) => requestSearch(event.target.value)}
        clearSearch={() => requestSearch('')}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns}
        loading={loading}
        pageSize={10}
        autoHeight={true}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        onCellClick={({ row }) => {
          setModalUser(row)
          setModalOpen(true)
        }}
        className={classes.addressesGrid}
      />
      <EditUserModal
        user={modalUser}
        open={modalOpen}
        onClose={() => {
          setModalUser(null)
          setModalOpen(false)
        }}
      />
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  hideRightSeparator: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}))

export default UsersTable
