import { FormControl, TextField } from '@mui/material'

/**
 * MUI doesn't have a native dropdown – this component is taken from the MUI docs
 */
const DropDownMenu = ({ className = '', label, value, setValue, children }) => {
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <FormControl fullWidth className={className}>
      <TextField
        variant='outlined'
        select
        value={value}
        onChange={handleChange}
        label={label}>
        {children}
      </TextField>
    </FormControl>
  )
}

export default DropDownMenu
