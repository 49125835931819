import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { SuccessAnimation } from '../SuccessAnimation'
import DropDownMenu from '../DropDownMenu'
import useAllOrgs from '../../hooks/useAllOrgs'
import useUpdateUser from '../../hooks/useUpdateUser'

/**
 * A modal component for editing users from the admin interface
 */
const EditUserModal = ({ user, open, onClose }) => {
  const classes = useStyles()
  const { orgs } = useAllOrgs()
  const updateUser = useUpdateUser()

  const [loading, setOnlyLoading] = useState(false)
  const [success, setOnlySuccess] = useState(false)

  // local changes to user
  const [validationStatus, setValidationStatus] = useState(null)
  const [role, setRole] = useState(null)
  const [name, setName] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [notify, setNotify] = useState(null)

  // reset state on close
  const handleOnClose = () => {
    setOnlyLoading(false)
    setOnlySuccess(false)
    setValidationStatus(null)
    setRole(null)
    setName(null)
    setOrganizationId(null)
    setNotify(null)
    onClose()
  }

  // when the user is passed in, we want the local state variables to reflect that user
  useEffect(() => {
    if (!user) return
    const {
      validation_status: validationStatus,
      role,
      name,
      organization_id: organizationId,
      notify,
    } = user
    setValidationStatus(validationStatus)
    setRole(role)
    setName(name)
    setOrganizationId(organizationId)
    setNotify(notify)
  }, [user])

  // set up a request to update the user in question
  const handleOnSave = async () => {
    setLoading(true)

    // organizationId is initially coming in as the organization name selected by the admin,
    // so we need to find which organization was selected.
    const selectedOrganization = orgs.find(
      (org) => org.organization_name === organizationId
    )

    const body = {
      email: user?.email,
      role,
      validationStatus,
      organizationId: selectedOrganization.organization_id,
      name,
      notify,
    }

    const result = await updateUser(body)
    if (result) {
      setSuccess(true)
      setTimeout(() => {
        handleOnClose()
      }, 2300)
    } else {
      alert('Sorry! Something went wrong.')
    }
  }

  // since loading and success are codependent, we set them together

  const setLoading = (status) => {
    setOnlyLoading(status)
    setOnlySuccess(!status)
  }

  const setSuccess = (status) => {
    setOnlySuccess(status)
    setOnlyLoading(!status)
  }

  // a keypress handler so pressing the 'Enter' key causes the submit action
  const onKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleOnSave()
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Paper elevation={3} className={classes.modal}>
          {success && !loading && <SuccessAnimation />}
          {loading && !success && (
            <Box
              sx={{
                display: 'grid',
                alignContent: 'center',
                justifyContent: 'center',
                height: '100%',
              }}>
              <CircularProgress color='secondary' />
            </Box>
          )}
          {!success && !loading && (
            <>
              <Typography variant='h6' className={classes.email}>
                {user?.email}
              </Typography>
              <TextField
                label={'Name'}
                fullWidth
                className={classes.input}
                defaultValue={user?.name}
                onKeyPress={onKeyPress}
                onChange={(event) => setName(event.target.value)}
              />

              <DropDownMenu
                className={classes.input}
                label={'Validation Status'}
                value={validationStatus}
                setValue={setValidationStatus}>
                <MenuItem value={true}>Validated</MenuItem>
                <MenuItem value={false}>Pending</MenuItem>
              </DropDownMenu>

              <DropDownMenu
                className={classes.input}
                label={'role'}
                value={role}
                setValue={setRole}>
                <MenuItem value={'ISP'}>ISP</MenuItem>
                <MenuItem value={'ADMIN'}>Admin</MenuItem>
              </DropDownMenu>

              <DropDownMenu
                className={classes.input}
                label={'organizationName'}
                value={organizationId}
                setValue={setOrganizationId}>
                {Array.isArray(orgs) &&
                  orgs.map((org) => (
                    <MenuItem
                      value={org.organization_name}
                      key={org.organization_name}>
                      {org.organization_name}
                    </MenuItem>
                  ))}
              </DropDownMenu>

              <Button
                className={classes.button}
                size='large'
                color='success'
                variant='contained'
                onClick={handleOnSave}>
                Save
              </Button>
            </>
          )}
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  email: {
    marginBottom: theme.spacing(1),
  },
  input: {
    margin: `${theme.spacing(1)} 0`,
  },
  modal: {
    padding: theme.spacing(4),
    borderRadius: '25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 445,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
}))

export default EditUserModal
