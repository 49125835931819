/*
 This file determines which environment we're in, and provides the app with the appropriate config
 */

const ciBaseURI = 'https://8mbv6lrj1l.execute-api.us-west-2.amazonaws.com/ci'
const prodBaseURI =
  'https://7nx8mbvr99.execute-api.us-west-2.amazonaws.com/production'

export const defaults = {
  port: 1234,
}

export const buildServices = (baseURI) => ({
  services: {
    userDetails: `${baseURI}/user`,
    userFiles: `${baseURI}/files`,
    allUsers: `${baseURI}/users`,
    orgDetails: `${baseURI}/org`,
    allOrgs: `${baseURI}/orgs`,
    filePresignedURL: `${baseURI}/presignedurl`,
  },
})

const config = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return buildServices(prodBaseURI)
    default:
      return buildServices(ciBaseURI)
  }
}

export default {
  ...defaults,
  ...config(),
}
