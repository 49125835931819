import { Box, IconButton, TextField } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'

/*
 contents of this file were taken almost whole-cloth from the MUI website. there is an example for
 MUI 5 under the data table filtering section of the docs where you can find more.
 */

export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const gridFilter = (searchText) => (row) => {
  const searchRegex = new RegExp(escapeRegExp(searchText), 'i')
  return Object.keys(row).some((field) => {
    return searchRegex.test(row[field]?.toString())
  })
}

export const SearchToolbar = ({ value, onChange, clearSearch }) => (
  <Box
    sx={{
      p: 0.5,
      pb: 0,
      display: 'inline',
    }}>
    <TextField
      variant='standard'
      value={value}
      onChange={onChange}
      placeholder='Search…'
      InputProps={{
        startAdornment: <Search fontSize='small' />,
        endAdornment: (
          <IconButton
            title='Clear'
            aria-label='Clear'
            size='small'
            style={{ visibility: value ? 'visible' : 'hidden' }}
            onClick={clearSearch}>
            <Clear fontSize='small' />
          </IconButton>
        ),
      }}
      sx={{
        width: {
          xs: 1,
          sm: 'auto',
        },
        m: (theme) => theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          mr: 0.5,
        },
        '& .MuiInput-underline:before': {
          borderBottom: 1,
          borderColor: 'divider',
        },
      }}
    />
  </Box>
)

export default SearchToolbar
