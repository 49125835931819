import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { Auth0Provider } from '@auth0/auth0-react'
import { UserProvider } from './state/user/context'
import Home from './routes/Home'
import FileDashboard from './routes/FileDashboard'
import Profile from './routes/Profile'
import Admin from './routes/Admin'
import { CssBaseline } from '@mui/material'

const App = () => {
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/dashboard`}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      audience='frappe-snowland-api'>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/dashboard' element={<FileDashboard />} />
              <Route path='/Admin' element={<Admin />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </UserProvider>
    </Auth0Provider>
  )
}

export default App
