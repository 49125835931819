import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import config from '../utils/config'

/**
 * a custom hook for retrieving files for the logged in user
 *
 * @prop {Object} defaultValue - a default value for the hook to take prior to the user files
 * loading. we set it to an object containing a dummy ID and an empty entity name so that when
 * rendering to an mui-data-grid, a single empty row while the user files load
 */
export const useUserFiles = (
  defaultValue = {
    availableAddresses: [],
    processedAddresses: [],
  }
) => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)
  const [userFiles, setUserFiles] = useState(defaultValue)
  const [fetches, setFetches] = useState(1)
  const [loading, setLoading] = useState(true)

  const refetchData = () => {
    setFetches(fetches + 1)
    setLoading(true)
  }

  // asynchronously fetch our access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: 'frappe-snowland-api',
      })
      setToken(token)
    }
    getAccessToken()
  }, [])

  // once we have the access token, use it to fetch more user details

  useEffect(() => {
    if (!token) return

    const fetchUserFiles = async () => {
      const response = await fetch(config.services.userFiles, {
        headers: {
          authorization: token,
        },
      })
      const results = await response.json()
      setUserFiles(results)
      setLoading(false)
    }
    fetchUserFiles()
  }, [token, fetches])

  return { ...userFiles, refetchData, loading }
}

export default useUserFiles
