import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import config from '../utils/config'

/**
 * a custom hook for retrieving files for the logged in user
 */
export const useUserDetails = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)
  const [userDetails, setUserDetails] = useState(null)

  // asynchronously fetch our access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: 'frappe-snowland-api',
      })
      setToken(token)
    }
    getAccessToken()
  }, [])

  // once we have the access token, use it to fetch more user details
  useEffect(() => {
    if (!token) return

    const fetchUserDetails = async () => {
      const response = await fetch(config.services.userDetails, {
        headers: {
          authorization: token,
          accept: 'application/json',
        },
      })
      const details = await response.json()
      setUserDetails(details)
    }
    fetchUserDetails()
  }, [token])

  return userDetails
}

export default useUserDetails
