/**
 * This action is for setting our User object
 *
 * @param {Object} userDetails - an object containing details to be set as User in context
 * @returns {Object} - an action object to be caught by our reducer
 */
export const setUserAction = (userDetails) => ({
  type: 'setUser',
  userDetails,
})

/**
 * This action is for adding / updating details on our User object
 *
 * @param {Object} userDetails - an object containing details to be added to / updated on our user
 * object
 * @returns {Object} - an action object to be caught by our reducer
 */
export const addToUserAction = (userDetails) => ({
  type: 'addToUser',
  userDetails,
})
