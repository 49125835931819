import { makeStyles } from '@mui/styles'
import { Button, Paper, Typography, Tooltip } from '@mui/material'
import { withAuthLayout } from '../../components/withLayout'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import { useUser } from '../../state/user/context'
import useUserFiles from '../../hooks/useUserFiles'
import HorizontalUL from '../../components/HorizontalUL'
import HelpIcon from '@mui/icons-material/Help'
import USStates from '../../constants/USStates'
import useFetchFile from '../../hooks/useFetchFile'
import { useState } from 'react'
import { UploadModal, UserGuideModal } from '../../components/Modal'
import { filterFiles } from '../../utils/file'

/* 
 this proxy allows us to map US State codes to their respective names, and to map anything passed
 in which is NOT a state code to its own value. if, however, that value is null or undefined, we'll
 opt to display nothing.
 */
const entityMap = new Proxy(USStates, {
  get: function (object, prop) {
    return object.hasOwnProperty(prop) ? object[prop] : prop ?? ' '
  },
})

/**
 * A route for the file dashboard page
 */
const FileDashboard = () => {
  const classes = useStyles()
  const userDetails = useUser()
  const { availableAddresses, processedAddresses, refetchData, loading } =
    useUserFiles()
  const filteredAddresses = filterFiles(availableAddresses, processedAddresses)
  // const [gridSelection, setGridSelection] = useState([])
  const fetchFile = useFetchFile()
  const [modalOpen, setModalOpen] = useState(false)

  const closeUploadModal = (shouldRefetchData) => {
    setModalOpen(false)
    shouldRefetchData && refetchData()
  }

  const availableAddressColumns = [
    {
      field: 'id',
      headerClassName: 'header',
      headerName: 'ID',
      width: 90,
      hide: 'true',
    },
    {
      field: 'entityName',
      headerName: 'District/State',
      headerClassName: 'header',
      flex: 3,
      renderCell: (params) => entityMap[params.value],
    },
    {
      field: 'entityCode',
      renderHeader: () => (
        <>
          {'Entity-State-FRN '}
          <Tooltip
            arrow
            placement='top'
            title="This string will be part of the name of the downloaded file. For reference: the first number is the state or district's ESH ID, followed by the state code and then your FRN.">
            <HelpIcon sx={{ fontSize: 15, marginLeft: 0.5 }} />
          </Tooltip>
        </>
      ),
      headerClassName: 'header',
      flex: 3,
    },
    {
      field: 'numAddresses',
      headerName: 'Addresses to Process',
      headerClassName: 'header',
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      flex: 2,
    },
    // TODO if/when we have data for this 😬
    // {
    //   field: 'LastModified',
    //   headerName: 'Date Ingested',
    //   headerClassName: 'header ' + classes.hideRightSeparator,
    //   type: 'date',
    //   headerAlign: 'right',
    //   align: 'right',
    //   flex: 1,
    //   valueFormatter: (date) => {
    //     return new Date(date.value).toLocaleDateString()
    //   },
    // },
  ]

  const processedAddressColumns = [
    {
      field: 'id',
      headerClassName: 'header',
      headerName: 'ID',
      width: 90,
      hide: 'true',
    },
    {
      field: 'entityName',
      headerName: 'District/State',
      headerClassName: 'header',
      flex: 3,
      renderCell: (params) => entityMap[params.value],
    },
    {
      field: 'serviceabilityCount_none',
      headerName: 'No Service Available',
      headerClassName: 'header',
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      flex: 2,
    },
    {
      field: 'serviceabilityCount_available',
      headerName: 'Service Available',
      headerClassName: 'header',
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      flex: 2,
    },
    {
      field: 'serviceabilityCount_active',
      headerName: 'Active Service',
      headerClassName: 'header ' + classes.hideRightSeparator,
      type: 'number',
      headerAlign: 'right',
      align: 'right',
      flex: 2,
    },
  ]

  return (
    userDetails && (
      <Paper elevation={3} className={classes.providerPanel}>
        <Typography className={classes.providerTitle} variant='h1'>
          {userDetails.organization_name}
        </Typography>
        <Box sx={{ display: 'flex', marginBottom: '2em' }}>
          <Typography variant='h6' sx={{ flex: 1 }}>
            Associated FRNs
          </Typography>
          <UserGuideModal />
        </Box>
        <HorizontalUL className={classes.frnGrid} columns={4}>
          {userDetails?.frns?.map(({ frn }, i) => (
            <li key={`${frn}-${i}`}>{frn}</li>
          ))}
        </HorizontalUL>
        <Box sx={{ display: 'flex', marginBottom: '2em' }}>
          <Typography variant='h4' sx={{ flex: 1 }}>
            Addresses Available
          </Typography>
          {/* TODO uncomment this when we enable multi-download */}
          {/* <Button
            color='secondary'
            className={classes.button}
            onClick={() =>
              fetchFile(gridSelection.map((el) => userFiles[el].Key))
            }
            size='large'
            variant='contained'>
            Download Selected
          </Button> */}
        </Box>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }} className={classes.root}>
            <DataGrid
              rows={filteredAddresses}
              columns={availableAddressColumns}
              loading={loading}
              pageSize={10}
              autoHeight={true}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              onCellClick={({ row: { Key } }) => fetchFile(Key)}
              className={classes.addressesGrid}
              // checkboxSelection
              // onSelectionModelChange={(newSelectionModel) => {
              //   setGridSelection(newSelectionModel)
              // }}
              // selectionModel={gridSelection}
            />
          </div>
        </div>
        <Box sx={{ display: 'flex', marginBottom: '2em', marginTop: '5em' }}>
          <Typography variant='h4' sx={{ flex: 1 }}>
            Processed Addresses
          </Typography>
          <Button
            color='secondary'
            className={classes.button}
            onClick={() => setModalOpen(true)}
            size='large'
            variant='contained'>
            Upload Processed Addresses
          </Button>
        </Box>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }} className={classes.root}>
            <DataGrid
              rows={processedAddresses}
              columns={processedAddressColumns}
              pageSize={10}
              loading={loading}
              autoHeight={true}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              className={classes.addressesGrid}
            />
          </div>
        </div>
        <UploadModal
          availableAddresses={filteredAddresses}
          open={modalOpen}
          onClose={closeUploadModal}
        />
      </Paper>
    )
  )
}

const useStyles = makeStyles((theme) => ({
  frnGrid: {
    marginBottom: theme.spacing(6),
    margin: '0 32px',
  },
  uploadModal: {
    padding: theme.spacing(4),
    borderRadius: '5px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
  addressesGrid: {
    minHeight: '370px',
    border: 'none',
  },
  providerPanel: {
    margin: `${theme.spacing(12)} auto auto auto`,
    width: '80vw',
    minWidth: '800px',
    padding: theme.spacing(4),
    borderRadius: '25px',
  },
  providerTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.quaternary,
    fontSize: '3.5em',
    fontWeight: 700,
  },
  hideRightSeparator: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
  uploadButton: {
    marginTop: '1em',
  },
  root: {
    '& .header': {
      color: theme.palette.info.main,
      fontSize: '18px',
      borderBottom: `4px solid ${theme.palette.info.main}`,
    },
    '& .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: '#FAFAFA',
      '&:hover': {
        backgroundColor: '#EDEDED',
      },
    },
    '& .MuiDataGrid-overlay': {
      minHeight: '150px',
    },
    '& .MuiDataGrid-row': {
      minHeight: '150px',
      cursor: 'pointer',
    },
  },
}))

export default withAuthLayout(FileDashboard)
