/**
 * this util function takes in 2 arrays of S3 contents (the results of a List Objects request) and
 * does its darndest to remove elements from the first list which DEFINITELY appear in the second
 * list.
 *
 * the reason why it's a hearty effort, and not a certainty, is that while we're sure of the key
 * names of the former list, the latter list may include legacy uploads with non-conforming key
 * names.
 *
 * in order to filter as best possible, we look for the entity IDs from the first list in the
 * objects from the second, ensuring that the entity ID is in isolation. in this case, that means
 * that it is not bordered by other numbers – this is complicated to explain, and is best
 * illustrated in th examples found in the tests.
 *
 * @param {Array} files - an array of files ("files") from an S3 query on which to filter
 * @param {Array} filter - an array of files (again, scare quotes) from an S3 query to use as a
 * filter
 * @returns {Array} – a filtered version of the files array
 */
export const filterFiles = (files, filter) => {
  // type check the inputs
  if (!Array.isArray(files) || !Array.isArray(filter)) return files

  return files.filter((file) => {
    const regex = fileKeyRegex(file.entityId)
    return !filter.some(({ Key }) => regex.test(Key))
  })
}

/**
 * This util function is used to find a matching key from amongst a selection, and return it should
 * one be found.
 *
 * @param {Object} file – the file (key) from S3 to match against
 * @param {Array} selection – a selection of files (keys) from S3 to search amongst
 * @param {Array} frns – a list of the users associated FRNs, which helps to filter the file names
 * @returns {Object|undefined} — a matching object from the selection, if one exists
 */
export const matchFile = (key, selection, frns) => {
  // make sure we're set up for success
  if (!key || !Array.isArray(selection) || !Array.isArray(frns)) return null
  // extract the likely key from the key, using the user's frns
  const entityIdGuess = guessEntityId(key, frns)
  //  build pattern to search for
  const regex = fileKeyRegex(entityIdGuess)
  //  search for that pattern, and return any result
  return selection.find(({ Key }) => regex.test(Key)) || null
}

/**
 * This util function is used to guess an entity id from a file key (name).
 *
 * @param {string} key – the key (filename) for the file in question
 * @param {Array} fullFrns – an array of frn objects, from useUser
 * @returns {string|null} – the best guess at an entity id, or null if one is not easily guessable
 */
export const guessEntityId = (key, frns) => {
  if (!key || !Array.isArray(frns)) return null
  // start the guess as just the filename, without path or file extension
  let guess = key.substring(key.lastIndexOf('/') + 1, key.lastIndexOf('.'))

  // remove any applicable frn from the string, and remove non-numerical characters
  for (let { frn } of frns) {
    guess = guess
      .replaceAll(frn, '')
      .split(/[^0-9]/g)
      .filter(Boolean)[0]
  }

  // send back our guess, unless it's falsy, then let's nullify our response to make that clear
  return guess || null
}

/**
 * This util function is used to build a regular expression to run against filenames to find a
 * potential match.
 *
 * @param {Object} file – a file (key) from S3 to build the regex against
 * @returns {RegExp} – a regular expression used to match keys likely to match the input file
 */
export const fileKeyRegex = (entityId) =>
  new RegExp(`([^0-9]|^)${entityId}([^0-9]|$)`, 'g')
