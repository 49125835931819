import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#ccc' /* gray */,
      light: '#fbfbfb' /* lightGray */,
      dark: '#222' /* black */,
    },
    secondary: {
      main: '#5E083E' /* purple */,
      light: '#d7dada' /* gray */,
    },
    info: {
      main: '#5E083E' /* purple */,
      active: '#47062f' /* purple: 1 shade darker */,
      light: '#158acf' /* skyBlue */,
    },
    success: {
      main: '#019297' /* mutedBlue */,
      light: '#FBBB0B' /* yellow */,
      active: '#016164' /* mutedBlue: 2 shades darker */,
    },
    error: {
      main: '#b00' /* red */,
    },
    text: {
      secondary: '#565656',
      tertiary: '#666',
      quaternary: '#17214d',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
})

export default theme
