import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import config from '../utils/config'

/**
 * a custom hook for updating information on a org
 */
export const useUpdateOrg = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)
  const [updateOrg, setUpdateOrg] = useState(() => () => ({
    organizationId: null,
    organizationName: null,
    serviceProviderIds: null,
  }))

  // asynchronously fetch our access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: 'frappe-snowland-api',
      })
      setToken(token)
    }
    getAccessToken()
  }, [])

  // once we have the access token, use it to update the information on the org
  useEffect(() => {
    if (!token) return

    const putUpdateOrg = async (options) => {
      let {
        serviceProviderIds: service_provider_ids,
        organizationId: organization_id,
        organizationName: organization_name,
      } = options

      if (Array.isArray(service_provider_ids)) {
        service_provider_ids = service_provider_ids.filter(Boolean)
      }

      const response = await fetch(config.services.orgDetails, {
        headers: {
          authorization: token,
        },
        method: 'PUT',
        body: JSON.stringify({
          service_provider_ids,
          organization_id,
          organization_name,
        }),
      })

      return response.ok
    }
    setUpdateOrg(() => putUpdateOrg)
  }, [token])

  return updateOrg
}

export default useUpdateOrg
