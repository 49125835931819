import { makeStyles } from '@mui/styles'
import { Paper, Tab, Tabs, Box } from '@mui/material'
import { withAuthLayout } from '../../components/withLayout'
import { useState } from 'react'
import UsersTable from '../../components/Admin/UsersTable'
import OrgsTable from '../../components/Admin/OrgsTable'
import { a11yProps, TabPanel } from '../../components/TabPanel'

/**
 * the Admin dashboard
 */
const Admin = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(0)
  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
  }
  return (
    <Paper className={classes.adminPanel}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
        <Tabs
          value={currentTab}
          onChange={handleTabsChange}
          aria-label='Admin Tabs'>
          <Tab label='Users' {...a11yProps(0)} />
          <Tab label='Organizations' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <UsersTable />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <OrgsTable />
      </TabPanel>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  adminPanel: {
    margin: `${theme.spacing(12)} auto auto auto`,
    width: '80vw',
    minWidth: '800px',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
  },
}))

export default withAuthLayout(Admin)
