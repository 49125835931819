import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  TextField,
} from '@mui/material'
import { SuccessAnimation } from '../SuccessAnimation'
import useUpdateOrg from '../../hooks/useUpdateOrg'

/**
 * A modal component for adding/editing orgs from the admin interface
 */
const OrgModal = ({ org, open, onClose }) => {
  const classes = useStyles()
  const updateOrg = useUpdateOrg()

  const [loading, setOnlyLoading] = useState(false)
  const [success, setOnlySuccess] = useState(false)

  // local changes to the org
  const [organizationName, setOrganizationName] = useState('')
  const [serviceProviderIds, setServiceProviderIds] = useState('')

  // reset state on close
  const handleOnClose = () => {
    setOnlyLoading(false)
    setOnlySuccess(false)
    setOrganizationName('')
    setServiceProviderIds('')
    onClose()
  }

  // when the org is passed in, we want the local state variables to reflect that org
  useEffect(() => {
    if (!org) return
    const {
      organization_name: organizationName,
      service_provider_ids: serviceProviderIds,
    } = org
    setOrganizationName(organizationName)
    setServiceProviderIds(serviceProviderIds)
  }, [org])

  // set up a request to update the org in question
  const handleOnSave = async () => {
    setLoading(true)
    // if we're creating a new org, this will be null, and trigger a new org to be created
    const organizationId = org?.organization_id

    const body = {
      organizationName,
      organizationId,
      serviceProviderIds,
    }
    const result = await updateOrg(body)
    if (result) {
      setSuccess(true)
      setTimeout(() => {
        handleOnClose()
      }, 2300)
    } else {
      alert('Sorry! Something went wrong.')
    }
  }

  // since loading and success are codependent, we set them together

  const setLoading = (status) => {
    setOnlyLoading(status)
    setOnlySuccess(!status)
  }

  const setSuccess = (status) => {
    setOnlySuccess(status)
    setOnlyLoading(!status)
  }

  // a keypress handler so pressing the 'Enter' key causes the submit action
  const onKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleOnSave()
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Paper elevation={3} className={classes.modal}>
          {success && !loading && <SuccessAnimation height={196} />}
          {loading && !success && (
            <Box
              sx={{
                display: 'grid',
                alignContent: 'center',
                justifyContent: 'center',
                height: 196,
              }}>
              <CircularProgress color='secondary' />
            </Box>
          )}
          {!success && !loading && (
            <>
              <TextField
                label={'Name'}
                fullWidth
                className={classes.input}
                value={organizationName}
                onKeyPress={onKeyPress}
                onChange={(event) => setOrganizationName(event.target.value)}
              />
              <TextField
                label={'Service Provider IDs'}
                fullWidth
                multiline
                className={classes.input}
                value={String(serviceProviderIds)}
                onKeyPress={onKeyPress}
                onChange={(event) => {
                  const ids = event.target.value.split(',')
                  setServiceProviderIds(ids)
                }}
              />

              <Button
                className={classes.button}
                size='large'
                color='success'
                variant='contained'
                onClick={handleOnSave}>
                Save
              </Button>
            </>
          )}
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  input: {
    margin: `${theme.spacing(1)} 0`,
  },
  modal: {
    padding: theme.spacing(4),
    borderRadius: '25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    minHeight: 260,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
}))

export default OrgModal
