import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import config from '../utils/config'

/**
 * a custom hook for retrieving information on all orgs
 */
export const useAllOrgs = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)
  const [orgs, setAllOrgs] = useState(null)
  const [fetches, setFetches] = useState(1)
  const [loading, setLoading] = useState(true)

  const refetchOrgs = () => {
    setFetches(fetches + 1)
    setLoading(true)
  }

  // asynchronously fetch our access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: 'frappe-snowland-api',
      })
      setToken(token)
    }
    getAccessToken()
  }, [])

  // once we have the access token, use it to fetch information on all orgs
  useEffect(() => {
    if (!token) return

    setLoading(true)

    const fetchAllOrgs = async () => {
      const response = await fetch(config.services.allOrgs, {
        headers: {
          authorization: token,
        },
      })
      const details = await response.json()
      setAllOrgs(details)
      setLoading(false)
    }
    fetchAllOrgs()
  }, [token, fetches])

  return { orgs, refetchOrgs, loading }
}

export default useAllOrgs
