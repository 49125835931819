import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import config from '../utils/config'

/**
 * a custom hook for updating information on a user
 */
export const useUpdateUser = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)
  const [updateUser, setUpdateUser] = useState(() => () => ({
    email: null,
    role: null,
    validationStatus: null,
    organizationId: null,
    name: null,
    notify: null,
  }))

  // asynchronously fetch our access token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: 'frappe-snowland-api',
      })
      setToken(token)
    }
    getAccessToken()
  }, [])

  // once we have the access token, use it to update the information on the user
  useEffect(() => {
    if (!token) return

    const postUpdateUser = async (options) => {
      const {
        email,
        role,
        validationStatus: validation_status,
        organizationId: organization_id,
        name,
        notify,
      } = options

      const response = await fetch(config.services.userDetails, {
        headers: {
          authorization: token,
        },
        method: 'POST',
        body: JSON.stringify({
          email,
          role,
          validation_status,
          organization_id,
          name,
          notify,
        }),
      })

      return response
    }
    setUpdateUser(() => postUpdateUser)
  }, [token])

  return updateUser
}

export default useUpdateUser
